












































































































































































import { Component, Emit, Model, Vue, Watch } from "vue-property-decorator";
import { CameraResultType, CameraSource, Plugins } from "@capacitor/core";

import App from "@/app/modules/_global/classes/App";
import Screen from "@/app/modules/_global/classes/Screen";

const { Camera } = Plugins;

@Component
export default class NavigationDrawerComponent extends Vue {
  @Model("input", { type: Boolean }) readonly navDrawer!: boolean;

  @Emit()
  input() {
    return this.localNavDrawer;
  }

  @Watch("localNavDrawer")
  onLocalNavDrawerChanged() {
    this.input();
  }

  @Watch("navDrawer")
  onNavDrawerChanged() {
    if (JSON.stringify(this.localNavDrawer) != JSON.stringify(this.navDrawer)) {
      this.localNavDrawer = this.navDrawer;
    }
  }

  localNavDrawer: boolean;

  userPhotoSource? = "";
  userPhotoDialog = false;
  boundedSidebarHeight = 0;

  constructor() {
    super();

    this.localNavDrawer = this.navDrawer;
  }

  created() {
    this.$nextTick(() => {
      const screen = new Screen();
      this.boundedSidebarHeight = screen.getBoundedSidebarHeight();
    });
  }

  get appData() {
    return this.$store.getters["app/data"];
  }

  get userData() {
    return this.$store.getters["profile/data"];
  }

  get buildingComplexData() {
    return this.$store.getters["app/buildingComplexData"];
  }

  get userInitials() {
    const firstName = this.userData.person.first_name.charAt(0);
    const lastName = this.userData.person.last_name.charAt(0);

    return `${firstName}${lastName}`;
  }

  get userAvatar() {
    return this.userData.person?.url_avatar;
  }

  get deviceInfo() {
    return this.$store.getters["device/deviceInfo"];
  }

  get appInfo() {
    const mode = this.$store.getters["app/mode"];
    let url = process.env.VUE_APP_CORE_URL;
    let endpointChar = "P";

    if (mode == "Dev") url = process.env.VUE_APP_CORE_DEV_URL;
    if (mode == "Test") url = process.env.VUE_APP_CORE_TEST_URL;

    if (url?.includes("dev")) endpointChar = "D";
    if (url?.includes("test")) endpointChar = "T";

    return `${endpointChar}.3.1`;
  }

  async takePicture() {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt
    });

    this.userPhotoSource = photo.webPath;

    const formData = new FormData();

    if (photo.webPath) {
      const blob = await fetch(photo.webPath).then(r => r.blob());
      formData.append("file", blob);

      this.$store.dispatch("profile/uploadAvatar", formData).then(({ data }) => {
        this.$store.dispatch("profile/attachAvatar", { upload_id: data.id }).then(() => {
          App.init({ auth: true });
        });
      });
    }
  }
}
